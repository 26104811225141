import type { IndustryOption } from 'paintscout'

export const industryOptions: IndustryOption[] = [
  {
    key: 'design',
    value: 'design',
    label: 'Design',
    thumbnailId: '_static/industry/thumbnails/design'
  },
  {
    key: 'blinds',
    value: 'blinds',
    label: 'Window Coverings',
    thumbnailId: '_static/industry/thumbnails/blinds'
  },
  {
    key: 'business',
    value: 'business',
    label: 'Business',
    thumbnailId: '_static/industry/thumbnails/business'
  },
  {
    key: 'landscaping',
    value: 'landscaping',
    label: 'Landscaping',
    thumbnailId: '_static/industry/thumbnails/landscaping'
  },
  {
    key: 'other',
    value: 'other',
    label: 'Other',
    thumbnailId: '_static/industry/thumbnails/other'
  }
]
