import { Auth0Client } from '@auth0/auth0-spa-js'
import { getDefaultAudience } from '@paintscout/util/users'

export const audience = `https://api.${getDefaultAudience()}`

// make sure openid is first in scope - otherwise localstorage key for auth0 session will be wrong
export const scope = 'openid read:current_user read:users write:users offline_access'

export const auth = new Auth0Client({
  domain: process.env.AUTH0_DOMAIN as string,
  client_id: process.env.AUTH0_ADMIN_CLIENT_ID as string,
  redirect_uri: `${process.env.PS_ADMIN_URL}/login-callback`,
  audience,
  scope,
  authorizeTimeoutInSeconds: 5,
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
  advancedOptions: {
    defaultScope: scope
  }
})
