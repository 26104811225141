import type { QuoteFile, PresentationIndustry } from 'paintscout'

// not using version (v) in urls so
// if replacing an image at the same publicId, set invalidate to true when uploading
// see: https://cloudinary.com/documentation/advanced_url_delivery_options#asset_versions

const landscapingPhotos: QuoteFile[] = [
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/landscaping/landscapingDog.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/landscaping/landscapingDog',
    width: 1920,
    height: 1251,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/landscaping/landscapingDuplex.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/landscaping/landscapingDuplex',
    width: 1920,
    height: 1282,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/landscaping/landscapingFamily.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/landscaping/landscapingFamily',
    width: 1920,
    height: 1281,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/landscaping/landscapingFarm.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/landscaping/landscapingFarm',
    width: 1920,
    height: 1280,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/landscaping/landscapingGrass.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/landscaping/landscapingGrass',
    width: 1920,
    height: 1280,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/landscaping/landscapingHouse.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/landscaping/landscapingHouse',
    width: 1920,
    height: 1279,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/landscaping/landscapingMower.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/landscaping/landscapingMower',
    width: 1920,
    height: 1272,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/landscaping/landscapingPatio.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/landscaping/landscapingPatio',
    width: 1920,
    height: 1440,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/landscaping/landscapingSod.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/landscaping/landscapingSod',
    width: 1920,
    height: 1280,
    visibility: 'visible'
  }
]

const designPhotos: QuoteFile[] = [
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/design/designBlueLivingroom.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/design/designBlueLivingroom',
    width: 1920,
    height: 1165,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/design/designBlueMug.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/design/designBlueMug',
    width: 1920,
    height: 1280,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/design/designConsole.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/design/designConsole',
    width: 1920,
    height: 1387,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/design/designGreenCouch.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/design/designGreenCouch',
    width: 1920,
    height: 1280,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/design/designKitchen.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/design/designKitchen',
    width: 1920,
    height: 1282,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/design/designPlants.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/design/designPlants',
    width: 1920,
    height: 1280,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/design/designSwatches.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/design/designSwatches',
    width: 1920,
    height: 1275,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/design/designTVRoom.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/design/designTVRoom',
    width: 1920,
    height: 1280,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/design/designTanLivingroom.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/design/designTanLivingroom',
    width: 1919,
    height: 1234,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/design/designYellowCouch.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/design/designYellowCouch',
    width: 1920,
    height: 1280,
    visibility: 'visible'
  }
]

const blindsPhotos: QuoteFile[] = [
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/blinds/blinds0.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/blinds/blinds0',
    width: 1920,
    height: 1080,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/blinds/blinds1.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/blinds/blinds1',
    width: 1920,
    height: 1280,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/blinds/blinds2.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/blinds/blinds2',
    width: 1919,
    height: 1281,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/blinds/blinds3.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/blinds/blinds3',
    width: 1919,
    height: 1292,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/blinds/blinds4.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/blinds/blinds4',
    width: 1920,
    height: 1440,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/blinds/blinds5.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/blinds/blinds5',
    width: 1920,
    height: 1282,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/blinds/blinds6.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/blinds/blinds6',
    width: 1920,
    height: 1280,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/blinds/blinds7.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/blinds/blinds7',
    width: 1920,
    height: 1280,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/blinds/blinds8.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/blinds/blinds8',
    width: 1920,
    height: 1281,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/blinds/blinds9.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/blinds/blinds9',
    width: 1919,
    height: 1281,
    visibility: 'visible'
  }
]

const businessPhotos: QuoteFile[] = [
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/business/business0.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/business/business0',
    width: 1920,
    height: 1280,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/business/business1.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/business/business1',
    width: 1920,
    height: 1280,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/business/business10.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/business/business10',
    width: 1920,
    height: 1281,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/business/business11.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/business/business11',
    width: 1920,
    height: 1280,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/business/business2.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/business/business2',
    width: 1920,
    height: 1280,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/business/business3.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/business/business3',
    width: 1920,
    height: 1280,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/business/business4.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/business/business4',
    width: 1920,
    height: 1280,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/business/business5.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/business/business5',
    width: 1920,
    height: 1281,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/business/business6.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/business/business6',
    width: 1920,
    height: 1280,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/business/business7.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/business/business7',
    width: 1920,
    height: 1280,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/business/business8.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/business/business8',
    width: 1920,
    height: 1280,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/business/business9.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/business/business9',
    width: 1920,
    height: 1080,
    visibility: 'visible'
  }
]

const dinoPhotos: QuoteFile[] = [
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/default/dino0.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/default/dino0',
    width: 1920,
    height: 1280,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/default/dino1.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/default/dino1',
    width: 1920,
    height: 1280,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/default/dino2.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/default/dino2',
    width: 1920,
    height: 1280,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/default/dino3.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/default/dino3',
    width: 1920,
    height: 1280,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/default/dino4.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/default/dino4',
    width: 1920,
    height: 1280,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/default/dino5.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/default/dino5',
    width: 1920,
    height: 1280,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/default/dino6.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/default/dino6',
    width: 1920,
    height: 2878,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/default/dino7.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/default/dino7',
    width: 1920,
    height: 1281,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/default/dino8.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/default/dino8',
    width: 1920,
    height: 1280,
    visibility: 'visible'
  },
  {
    src: 'https://res.cloudinary.com/stickybid/image/upload/_static/industry/default/dino9.jpg',
    format: 'jpg',
    cloudinaryPublicId: '_static/industry/default/dino9',
    width: 1920,
    height: 1281,
    visibility: 'visible'
  }
]

export function getPrefabImages(industry: PresentationIndustry): QuoteFile[] {
  switch (industry) {
    case 'landscaping':
      return landscapingPhotos
    case 'design':
      return designPhotos
    case 'blinds':
      return blindsPhotos
    case 'business':
      return businessPhotos
    case 'other':
      return dinoPhotos
    default:
      return landscapingPhotos
  }
}
