import { useEffect } from 'react'
import { useAuth } from 'react-auth0'

function Logout() {
  const { logout } = useAuth()

  useEffect(() => {
    logout({ returnTo: process.env.PS_ADMIN_URL })
    // eslint-disable-next-line
  }, [])

  return null
}

export default Logout
