import React, { useState, useMemo, useEffect } from 'react'

import type { Theme } from '@material-ui/core'
import { makeStyles, ClickAwayListener } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'

import PresentationNavContextProvider from '../PresentationNav/PresentationNavContext/PresentationNavContextProvider'
import PresentationNavMobile from '../PresentationNav/PresentationNavMobile/PresentationNavMobile'

import type { PresentationOption, PresentationSection, PresentationPage } from 'paintscout'

import sectionComponents from '../sections'
import {
  BannerSection,
  ButtonSection,
  CardsSection,
  DividerSection,
  GallerySection,
  ImageSection,
  LogoSection,
  QuoteSection,
  SocialLinksSection,
  SpacerSection,
  TermsSection,
  TestimonialsSection,
  TextSection,
  ThreeColumnSection,
  TwoColumnSection
} from '../sections'

import AddSectionButton from '../util/AddSectionButton'
import PageMenu from '../util/PageMenu'
import SectionMenu from '../util/SectionMenu'
import { getSectionPadding, SectionOptionsPopup } from '../util'
import IntroToEditorDialog from '../dialogs/IntroToEditorDialog'
// import NavHelpDialog from '../dialogs/NavHelpDialog'

// import { PresentationNavMobile } from '../PresentationNav'

import {
  getPresentationContentStyles,
  Grid,
  Placeholder,
  Typography,
  useClientOptions,
  useDialogs,
  useMediaQuery
} from '@ui/stickybid'

import { useSnackbar } from 'notistack'

import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined'

import Quote from '../../Quote/Quote'
import QuoteTerms from '../../Quote/QuoteTerms'

import type { PresentationSectionProps } from '../'

import {
  createPresentationSection,
  getObjectLabels,
  getPresentationLogo,
  getPresentationSectionOptions,
  getQuotePresentation
} from '@paintscout/util/builder'

// import findIndex from 'lodash/findIndex'

import classnames from 'classnames'
import type { QuoteView } from '../../Quote'
import { useQuote } from '../../context'
import { useFormikContext } from 'formik'
import useRouter from 'use-react-router'
import qs from 'query-string'

import { usePresentation } from '../../context'

import { loadPresentationFonts } from '../util'
import { replaceSectionImages } from '../util'
import { getPrefabImages } from '@paintscout/util/database/fixtures/sb/presentations/prefabImages'

import { useFrame } from 'react-frame-component'
import AcceptedBadge from '../util/AcceptedBadge'

import striptags from 'striptags'

const useStyles = makeStyles<Theme, any>(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      ...theme.typography.body1,
      ['@media print']: {
        display: 'none'
      }
    },
    printOnly: {
      opacity: 0,
      position: 'absolute',
      visibility: 'hidden',
      ['@media print']: {
        opacity: 1,
        position: 'static',
        visibility: 'visible'
      }
    },
    // pageBreak: {
    //   display: 'block',
    //   content: '""',
    //   pageBreakAfter: 'always'
    // },
    content: (props) => ({
      ...props.presentationContentStyles,
      overflowWrap: 'break-word'
    }),
    pageBorder: {
      // height: 0,
      // position: 'relative',
      // zIndex: 2,
      // width: '100%',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      color: theme.palette.secondary.main,
      '&:before, &:after': {
        content: '""',
        flex: 1,
        borderBottom: `1px dashed ${theme.palette.secondary.main}`
      },
      '&:before': {
        margin: '0 20px 0 -20px'
      },
      '&:after': {
        margin: '0 -20px 0 20px'
      }
    },
    addSectionButton: {
      display: 'none'
    },
    showAddSectionButton: {
      display: 'flex'
    },
    sectionWrapper: {
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      position: 'relative',
      borderCollapse: 'separate'
    },
    hideSectionOverflow: {
      overflow: 'hidden'
    },
    pointer: {
      cursor: 'pointer'
    },
    backgroundWrapper: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%'
    },
    widths: {
      marginLeft: 'auto',
      marginRight: 'auto',
      boxSizing: 'border-box',
      width: 1080,
      maxWidth: '100%',
      ['@media print']: {
        width: 'auto'
      }
    },
    widthsResponsive: {
      [theme.breakpoints.down('md')]: {
        width: 718
      },
      [theme.breakpoints.down('sm')]: {
        width: '96%'
      }
    },
    quoteSectionBorder: {
      border: `1px solid ${theme.palette.grey[800]}`,
      borderRadius: theme.borderRadius.md,
      margin: theme.spacing(2, 0),
      padding: theme.spacing(6, 6),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(4, 2)
      }
    },
    section: {
      position: 'relative',
      '&:hover': {
        '& $addSectionButton': {
          display: 'flex'
        },
        '& > $sectionWrapperHovered': {
          '&::before': {
            display: 'block'
          }
        },
        '& .sectionHoverMenu': {
          display: 'flex'
        }
      }
    },
    sectionWrapperHovered: {
      '&::before': {
        zIndex: 1,
        display: 'none',
        content: '""',
        position: 'absolute',
        left: -theme.spacing(3) + 2,
        right: -theme.spacing(3) + 2,
        top: -2,
        bottom: -2,
        border: `2px solid ${theme.palette.presentation.sectionBorder.hover}`,
        pointerEvents: 'none'
        //  borderRadius: theme.borderRadius['md'],
      }
    },
    focusBorder: {
      zIndex: 2,
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: -theme.spacing(3) + 2,
        right: -theme.spacing(3) + 2,
        top: -2,
        bottom: -2,
        border: `2px solid ${theme.palette.presentation.sectionBorder.focus}`,
        backgroundColor: '#ffffff',
        zIndex: -1
        //  borderRadius: theme.borderRadius['md'],
      }
    },
    newPagePadding: {
      paddingBottom: 0
    },
    dummyNav: {
      // cursor: 'pointer',
      position: 'relative',
      '&:hover': {
        '& $dummyNavText': {
          opacity: 1
        }
      }
    },
    dummyNavText: {
      position: 'absolute',
      zIndex: 3,
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      pointerEvents: 'none',
      opacity: 0,
      transition: 'opacity 350ms ease'
    },
    dummyNavToolbar: {
      padding: 0
    }
  }),
  { name: 'Presentation' }
)

export interface PresentationProps {
  classes?: StyleClasses<typeof useStyles>
  presentation?: PresentationOption
  page?: string
  isPreview?: boolean
  isScreenshot?: boolean
  editFromDialog?: boolean
  isSettings?: boolean
  presentationTheme?: Theme
  loadAllFonts?: boolean

  getHeight?: (height: number) => void
  onPresentationClick?: (key: string) => void
  handleCreatePage?: (newPage: PresentationPage, pageIndex: number) => void
  handleCreateSection?: (newSection: PresentationSection, sectionIndex: number, pageIndex: number) => void
  handleEditPage?: (page: PresentationPage, pageIndex: number) => void
  handleEditSection?: (index: number, section: PresentationSection, isDirty: boolean, pageIndex: number) => void
  handleReorderSections?: (pages: PresentationPage[]) => void
  handleDeleteSection?: (key: string, pageIndex: number) => void
  showHelp?: boolean
  filterTypes?: string[]
  view?: QuoteView
  hidePreviewFrame?: boolean
}

interface ClipboardItem {
  isCut: boolean
  copiedPageIndex: number
  copiedSection: PresentationSection
  copiedSectionIndex: number
}

function Presentation({
  editFromDialog,
  getHeight,
  handleCreatePage,
  handleCreateSection,
  handleDeleteSection,
  handleEditPage,
  handleEditSection,
  handleReorderSections,
  hidePreviewFrame,
  isScreenshot,
  isSettings = true,
  loadAllFonts,
  onPresentationClick,
  page,
  presentationTheme: presentationThemeProp,
  showHelp,
  view,
  ...props
}: PresentationProps) {
  const {
    isEditable,
    preview,
    focusedSection,
    setFocusedSection,
    presentationTheme: presentationThemeHook,
    addPages
  } = usePresentation()

  const presentationTheme = presentationThemeProp ?? presentationThemeHook
  const { setFieldValue, submitForm } = useFormikContext<PresentationOption>()
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
  const [clipboard, setClipboard] = useState<ClipboardItem | null>(null)
  const { dismissAllDialogs, openDialog, stack: dialogStack } = useDialogs()
  const { enqueueSnackbar } = useSnackbar()
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })
  const { quote, estimator } = useQuote()
  const presentation = props.presentation ?? getQuotePresentation({ options, quote, view, current: true })

  let presentationContentStyles = presentationTheme
    ? getPresentationContentStyles(presentationTheme, { noMargins: ['li'] })
    : {}
  useMemo(() => {
    presentationContentStyles = getPresentationContentStyles(presentationTheme, { noMargins: ['li'] })
  }, [presentationTheme])
  const classes = useStyles({ ...props, presentationContentStyles })

  const { window: win, document: doc } = useFrame()

  useEffect(() => {
    if (isSettings || loadAllFonts) {
      // load all google fonts
      loadPresentationFonts({ win, doc })
    } else {
      // if not making a presentation load only the google fonts we need to display it
      const fonts = []
      const style = presentation.advanced.style
      if (!style) return
      fonts.push(style.fontFamily)
      const typogs = ['body', 'heading1', 'heading2', 'heading3']
      typogs.forEach((typog) => {
        const family = style[typog]?.fontFamily
        if (family && fonts.indexOf(family) === -1) fonts.push(family)
      })
      const fontsToLoad = fonts.filter(
        (font) => !['default', 'degular, sans-serif', 'georgia, serif', 'roboto, sans-serif'].includes(font)
      )
      loadPresentationFonts({ win, doc, fontFamilies: fontsToLoad })
    }
  }, [])

  useEffect(() => {
    // give invoice presentations a background of theme.palette.grey['100']
    if (!isSettings && quote.is_invoice) {
      doc.querySelector('body').style.background = '#f5f5f5'
    }

    return () => {
      doc.querySelector('body').style.removeProperty('background')
    }
  }, [])

  const { location } = useRouter()
  const { isNew } = qs.parse(location.search) as {
    isNew: string
  }

  useEffect(() => {
    if (isSettings && window.localStorage.getItem('isIntroToEditorDialogDismissed') !== 'true' && isNew) {
      openDialog(IntroToEditorDialog, {
        onCancel: dismissAllDialogs
      })
    }
  }, [])

  const handleClickOn = (ev: React.MouseEvent<Element, MouseEvent>, sectionKey: string) => {
    if (isSettings && focusedSection !== sectionKey) {
      setFocusedSection(sectionKey)
      setMenuAnchorEl(null)
    }
  }

  const handleClickAway = (ev: React.MouseEvent<Document, MouseEvent>, sectionKey: string) => {
    if (focusedSection === sectionKey) {
      ev.stopPropagation()
      submitForm()
      setFocusedSection('')
      setMenuAnchorEl(null)
    }
  }

  // const filterTypes = props?.filterTypes ?? null

  const isPreview = props.isPreview ?? (!!preview && preview !== 'none')
  const editable = !isPreview && isSettings
  const isPrint = useMediaQuery('print')
  // get size from useMediaQuery
  let size: 'desktop' | 'tablet' | 'mobile' = 'desktop'
  const isTablet = useMediaQuery('md')
  const isMobile = useMediaQuery('sm')
  if (isTablet) size = 'tablet'
  if (isMobile) size = 'mobile'

  // const stacked = presentation?.advanced?.infiniteScroll
  // const stacked = true
  const presentationStyle = presentation.advanced.style
  const { palette } = presentationStyle || {}
  const presetColors = palette?.title ? Object.values(palette.colors) : undefined

  if (getHeight) {
    const presentationEl = doc.getElementById('presentation')
    if (presentationEl) {
      getHeight(presentationEl.offsetHeight)
    }
  }

  // const pageIndex = getPageIndex(page)
  // const groupedSections: PresentationSection[][] = []
  // const pageIds: string[] = []
  // If we're using the stacked layout, we want ALL the sections from ALL
  // the pages. Otherwise, just the sections from the active page.
  // if (stacked) {
  // presentation.pages.forEach((page) => {
  //   groupedSections.push(
  //     page.sections.filter(filterAllowedTypes).map(function (section, index) {
  //       return section
  //     })
  //   )
  //   pageIds.push(page.key)
  // })
  // } else {
  // pages.push(presentation.pages[pageIndex].sections.filter(filterAllowedTypes))
  // }
  const hasTerms = !!striptags(quote.terms?.content)

  return (
    <>
      {!isScreenshot && !editable && (
        <div className={classes.printOnly}>
          <Quote inPresentation printOnly />
          {hasTerms && (
            <>
              <div className={classes.pageBreak}></div>
              <QuoteTerms />
            </>
          )}
        </div>
      )}
      <AcceptedBadge quote={quote} />
      <div className={classes.root} id={'presentation'}>
        {editable && (
          <PresentationNavContextProvider
            allowResponse={false}
            presentation={presentation}
            view={view}
            isPreview={isPreview}
            isDummy
            isCustomerView
          >
            <div
              className={classes.dummyNav}
              // onClick={() => {
              //   openDialog(NavHelpDialog, {
              //     onCancel: dismissDialog
              //   })
              // }}
            >
              <Typography variant={'overline'} className={classes.dummyNavText}>
                See Preview to Try Navigation
              </Typography>
              <PresentationNavMobile classes={{ toolbar: classes.dummyNavToolbar }} />
            </div>
          </PresentationNavContextProvider>
        )}
        {presentation.pages.map((page, pageIndex) => {
          // const completePage = presentation.pages[pageIndex]
          if (page.sections.length === 0 && isSettings) {
            // empty page
            return (
              <div id={page.key} key={page.key}>
                {editable && <PageMenu index={pageIndex} tooltipTitle={'Add / Edit Block'} />}
                <div className={classnames(classes.padding, classes.newPagePadding)}>
                  <Placeholder
                    Icon={InsertDriveFileOutlinedIcon}
                    buttonText={'Add Section'}
                    onClick={() => document.getElementById('add-section-bottom-new-page').click()}
                  />
                </div>
                <AddSectionButton
                  key={`add-section-bottom-new-page`}
                  id={'add-section-bottom-new-page'}
                  addPages={addPages}
                  objectLabels={objectLabels}
                  onActionClick={(ev, actionKey) => handleAddSection(actionKey, 0, pageIndex)}
                  onAddPage={(page: PresentationPage) => handleCreatePage(page, pageIndex)}
                  presentationIndustry={presentation.advanced.industry}
                  presentationStyle={presentationStyle}
                />
              </div>
            )
          } else
            return (
              // page with section(s)
              <div id={page.key} key={page.key}>
                {editable && <PageMenu index={pageIndex} tooltipTitle={'Add / Edit Block'} />}
                {page.sections
                  .filter((section) => {
                    const isEmpty = sectionComponents?.[section.type]?.isEmpty
                    if (!isEmpty) {
                      return false
                    }

                    if (!isSettings && isEmpty({ quote, options, section, isPrint, isSettings, estimator })) {
                      return false
                    }

                    return true
                  })
                  .map((section, sectionIndex) => {
                    // const SectionComponent = sectionComponents?.[section.type]?.Section
                    const isQuoteSection = ['quote', 'work-order'].includes(section.type)
                    const showCopySection = !isQuoteSection && section.type !== 'terms'
                    // const useCart = presentation.advanced?.useCart
                    const sectionCount = page.sections.length
                    // quote section isn't rendered if !!useCart so we discount it from top/bottom
                    // const isTopSection = index === 0 || (index === 1 && page.sections[0].type === 'quote' && useCart)
                    // const isBottomSection =
                    // index === sectionCount - 1 ||
                    // (index === sectionCount - 2 && page.sections[sectionCount - 1].type === 'quote' && useCart)
                    // quote button is back in for now
                    const isTopSection = sectionIndex === 0
                    const isBottomSection = sectionIndex === sectionCount - 1

                    return (
                      <section key={section.key}>
                        <ClickAwayListener
                          onClickAway={(ev) => handleClickAway(ev, section.key)}
                          {...(dialogStack.length && { mouseEvent: false, touchEvent: false })}
                        >
                          <div className={classes.section} onClick={(ev) => handleClickOn(ev, section.key)}>
                            {isSettings && isEditable && (!addPages || (addPages && isTopSection)) && (
                              <AddSectionButton
                                key={`add-section-top-section-${section.key}`}
                                objectLabels={objectLabels}
                                focused={focusedSection === section.key}
                                classes={{
                                  root: classnames({
                                    [classes.addSectionButton]: true,
                                    [classes.showAddSectionButton]: focusedSection === section.key
                                  })
                                }}
                                onActionClick={(ev, actionKey) =>
                                  handleAddSection(actionKey, sectionIndex - 1, pageIndex)
                                }
                                addPages={addPages}
                                onAddPage={(page: PresentationPage) => handleCreatePage(page, pageIndex)}
                                presentationStyle={presentationStyle}
                                presentationIndustry={presentation.advanced.industry}
                              />
                            )}
                            {getSection({
                              type: section.type,
                              props: {
                                editFromDialog: editFromDialog,
                                focusedSection: focusedSection,
                                index: sectionIndex,
                                isEditable: editable && focusedSection === section.key,
                                isInvoice: quote.is_invoice,
                                isPreview: isPreview,
                                isSettings: isSettings,
                                objectLabels: objectLabels,
                                pageIndex: pageIndex,
                                presentationContentStyles: presentationContentStyles,
                                presentationIndustry: presentation.advanced.industry,
                                presetColors: presetColors,
                                section: section,
                                sectionPrefix: `pages[${pageIndex}].sections[${sectionIndex}]`,
                                setFieldValue: setFieldValue,
                                showHelp: showHelp,
                                size: size,
                                submitForm: submitForm,
                                useCart: presentation.advanced?.useCart,
                                WrapperComponent: SectionWrapper
                              },
                              logo: getPresentationLogo({ options, quote, presentation }).logo
                            })}
                            {isSettings && isEditable && (!addPages || (addPages && isBottomSection)) && (
                              <AddSectionButton
                                key={`add-section-bottom-section-${section.key}`}
                                objectLabels={objectLabels}
                                focused={focusedSection === section.key}
                                classes={{
                                  root: classnames({
                                    [classes.addSectionButton]: true,
                                    [classes.showAddSectionButton]: focusedSection === section.key
                                  })
                                }}
                                addPages={addPages}
                                onActionClick={(ev, actionKey) => handleAddSection(actionKey, sectionIndex, pageIndex)}
                                onAddPage={(page: PresentationPage) => handleCreatePage(page, pageIndex + 1)}
                                presentationStyle={presentationStyle}
                                presentationIndustry={presentation.advanced.industry}
                              />
                            )}
                            {/* {isSettings && !isPreview && focusedSection === section.key && ( */}
                            {isSettings && !isPreview && (
                              <>
                                <SectionMenu
                                  addPages={addPages}
                                  clipboard={clipboard}
                                  enqueueSnackbar={enqueueSnackbar}
                                  focusedSection={focusedSection}
                                  handleCreateSection={handleCreateSection}
                                  handleDeleteSection={handleDeleteSection}
                                  handleReorderSections={handleReorderSections}
                                  index={sectionIndex}
                                  pageIndex={pageIndex}
                                  presentation={presentation}
                                  section={section}
                                  sectionOptionsOpen={!!menuAnchorEl}
                                  setClipboard={setClipboard}
                                  setFocusedSection={setFocusedSection}
                                  setMenuAnchorEl={setMenuAnchorEl}
                                />
                                {focusedSection === section.key && !!menuAnchorEl && (
                                  <SectionOptionsPopup
                                    menuAnchorEl={menuAnchorEl}
                                    onClose={() => setMenuAnchorEl(null)}
                                    open
                                    sectionKey={section.key}
                                  />
                                )}
                              </>
                            )}
                          </div>
                        </ClickAwayListener>
                      </section>
                    )
                  })}
              </div>
            )
        })}
        {editable && <PageMenu index={-1} tooltipTitle={'Add Block'} />}
      </div>
    </>
  )

  // function getPageIndex(page: string) {
  //   if (!page) {
  //     return 0
  //   }

  //   const i = findIndex(presentation.pages, { key: page })
  //   return i >= 0 ? i : 0
  // }

  function handleAddSection(type: string, sectionIndex: number, pageIndex: number) {
    const prefabImages = getPrefabImages(presentation.advanced.industry)
    const sectionOptions = getPresentationSectionOptions({ options })
    const section = sectionOptions.find((section) => section.type === type)
    const newSection = createPresentationSection({ presentationSectionOption: section })
    const { section: newSectionWithImages } = replaceSectionImages({ section: newSection, images: prefabImages })
    handleCreateSection(newSectionWithImages, sectionIndex, pageIndex)
    setFocusedSection(newSection.key)
  }

  // function filterAllowedTypes(section: PresentationSection) {
  //   return filterTypes === null || filterTypes.includes(section?.type)
  // }
}

function getSection(args: { type: string; props: PresentationSectionProps; logo: string }) {
  const { logo, props, type } = args
  switch (type) {
    case 'logo':
      return <LogoSection logo={logo} {...props} />
    case 'banner':
      return <BannerSection {...props} />
    case 'button':
      return <ButtonSection {...props} />
    case 'text':
      return <TextSection {...props} />
    case 'image':
      return <ImageSection {...props} />
    case '2-column':
      return <TwoColumnSection {...props} />
    case '3-column':
      return <ThreeColumnSection logo={logo} {...props} />
    case 'cards':
      return <CardsSection {...props} />
    case 'gallery':
      return <GallerySection {...props} />
    case 'testimonials':
      return <TestimonialsSection {...props} />
    case 'divider':
      return <DividerSection {...props} />
    case 'social-links':
      return <SocialLinksSection {...props} />
    case 'spacer':
      return <SpacerSection {...props} />
    case 'quote':
      return <QuoteSection {...props} />
    case 'terms':
      return <TermsSection {...props} />
  }
}

export interface SectionWrapperProps extends PresentationSectionProps {
  children: React.ReactElement
}

const SectionWrapper = function ({
  children,
  index,
  section,
  isPreview,
  isInvoice,
  isSettings,
  pageIndex,
  size,
  presentationContentStyles,
  focusedSection,
  useCart,
  ...props
}: SectionWrapperProps) {
  const classes = useStyles({ ...props, presentationContentStyles })

  if (!section) {
    return null
  }

  const isQuoteSection = ['quote', 'work-order'].includes(section.type)
  const isEmbeddedQuote = isQuoteSection && !useCart
  const isInvoiceTerms = isInvoice && section.type === 'terms'

  const background = section?.background ?? {}
  const backgroundStyle = background?.style ?? 'full'
  const sectionStyles: any = {}
  if (background.color && !isEmbeddedQuote && !isInvoiceTerms) {
    sectionStyles.backgroundColor = background.color
  }
  if (background.textColor) {
    sectionStyles.color = background.textColor
  }

  const backgroundImage = background.image

  if (backgroundImage) {
    sectionStyles.background = `linear-gradient(rgba(0,0,0,${background.overlay}), rgba(0,0,0,${background.overlay})), url(${backgroundImage?.src}) center / cover no-repeat`
  }

  sectionStyles.padding = getSectionPadding({
    noTopPadding:
      pageIndex === 0 &&
      index === 0 &&
      (section.type === 'banner' || section.type === 'image' || section.type === 'logo'),
    section,
    source: 'presentation',
    size,
    returnDefault: isEmbeddedQuote
  })

  return (
    <div
      id={section.key}
      className={classnames({
        [classes.sectionWrapper]: true,
        [classes.hideSectionOverflow]: !isSettings,
        [classes.pointer]: isSettings && !isPreview && focusedSection !== section.key,
        [classes.sectionWrapperHovered]: true,
        [classes.content]: section.type !== 'work-order',
        [classes.focusBorder]: focusedSection === section.key
      })}
    >
      <div style={sectionStyles} className={background.useTheme ? 'themeBackground' : null}>
        <Grid
          container
          className={classnames({
            [classes.quoteSectionBorder]: false
          })}
          style={{ height: '100%' }}
        >
          <Grid item xs={12} style={{ height: '100%' }}>
            {children}
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default Presentation
