import React, { useState, useEffect, useMemo } from 'react'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CompanyIcon from '@material-ui/icons/LocationCity'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import PersonIcon from '@material-ui/icons/Person'
import EmergencyIcon from '@material-ui/icons/Error'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles, styled } from '@material-ui/core'
import classnames from 'classnames'
import type { Theme } from '@material-ui/core'
import { useAuth } from 'react-auth0'

import type { LinkProps } from 'react-router-dom'
import { Link } from 'react-router-dom'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import NavDrawerContext from './NavDrawerContext'
import history from '../../util/history'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    background: 'none'
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerHeader: {
    maxHeight: 50,
    padding: `10px 20px`,
    display: 'flex',
    alignItems: 'center'
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    marginLeft: 10,
    maxWidth: theme.breakpoints.values.xl,
    padding: theme.spacing(3)
  }
}))

const StyledDivider = styled(Divider)({
  margin: '0 10px',
  backgroundColor: 'rgba(0, 0, 0, 0.08)'
})

function DrawerLink(props: { text: string; icon: React.ComponentType<SvgIconProps>; to: LinkProps['to'] }) {
  const Icon = props.icon
  return (
    <ListItem
      button
      component={
        React.forwardRef(function LinkComponent(cProps, ref) {
          return <Link to={props.to} {...(cProps as any)} ref={ref} />
        }) as any
      }
    >
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={props.text} />
    </ListItem>
  )
}

function NavDrawer(props) {
  const { container } = props
  const { session, logout } = useAuth()

  const classes = useStyles({})

  const [mobileOpen, setMobileOpen] = useState(false)

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen)
  }

  // close drawer whenever route changes
  useEffect(() => {
    return history.listen(() => {
      setMobileOpen(false)
    })
  }, [])

  const drawer = (
    <div>
      <div className={classnames(classes.toolbar, classes.drawerHeader)}>
        <img
          src={'https://res.cloudinary.com/stickybid/image/upload/v1675120146/_static/sb_logo/sb-logo-200w.png'}
          alt={'StickyBid'}
          style={{ width: 120, height: 'auto', objectFit: 'contain' }}
        />
      </div>
      <StyledDivider />
      <List>
        {/* <DrawerLink text="Dashboard" to="/" icon={DashboardIcon} /> */}
        <DrawerLink text="Clients" to="/clients" icon={CompanyIcon} />
        <DrawerLink text="Users" to="/users" icon={PersonIcon} />
        <DrawerLink text="Emergency Banner" to="/emergency" icon={EmergencyIcon} />
      </List>
      <StyledDivider />
      <List>
        <ListItem button onClick={() => logout({ returnTo: process.env.PS_ADMIN_URL })}>
          <ListItemIcon>
            <ArrowBackIcon />
          </ListItemIcon>
          <ListItemText primary={'Logout'} />
        </ListItem>
      </List>
    </div>
  )

  const value = useMemo(
    () => ({
      open: mobileOpen
    }),
    [mobileOpen]
  )

  return (
    <NavDrawerContext.Provider value={value}>
      <div className={classes.root}>
        <CssBaseline />
        {!!session && (
          <>
            <AppBar position="static" className={classes.appBar} color="inherit" elevation={0}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
                <div id="nav-header-portal" />
              </Toolbar>
            </AppBar>
            <nav className={classes.drawer}>
              {/* Mobile drawer */}
              <Hidden mdUp implementation="css">
                <Drawer
                  container={container}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper
                  }}
                  ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                  }}
                >
                  {drawer}
                </Drawer>
              </Hidden>
              {/* Desktop drawer */}
              <Hidden mdDown implementation="css">
                <Drawer
                  classes={{
                    paper: classes.drawerPaper
                  }}
                  variant="permanent"
                  open
                >
                  {drawer}
                </Drawer>
              </Hidden>
            </nav>
          </>
        )}
        <main className={classes.content}>{props.children}</main>
      </div>
    </NavDrawerContext.Provider>
  )
}

export default NavDrawer
