import type { OptionsDocument } from 'paintscout'
import find from 'lodash/find'
import { getPlans } from '../get-plans'
import merge from 'lodash/merge'

export function changePlan(args: { options: OptionsDocument; plan: string }): OptionsDocument {
  const { options, plan } = args
  console.log('change plan', { plan })

  const plans = getPlans()
  const selectedPlan = find(plans, { value: plan })

  const updatedOptions = {
    ...options,
    options: {
      ...options.options,
      features: merge(options.options.features, selectedPlan.features)
    }
  }

  return updatedOptions
}
