import type { Auth0Client, LogoutOptions } from '@auth0/auth0-spa-js'
import type { Auth0Claims } from 'paintscout'
import React, { useContext, useMemo, useReducer, useEffect } from 'react'
import type { Auth0Session } from './util/session'

export interface AuthProviderProps {
  auth0Client: Auth0Client
  scope: string
  audience: string
  appUrl?: string
  /**
   * Is called before the user is redirected to the login page.
   * Use this to clear any local data that might exist.
   */
  onLogout?: () => any

  children: React.ReactNode | ((props: AuthContextValue) => React.ReactNode)
}

export interface AuthContextValue {
  auth0Client?: Auth0Client
  session?: Auth0Session
  isAuthenticating: boolean

  authenticate: (opts?: { ignoreCache?: boolean }) => Promise<Auth0Session | null>
  logout: (options?: LogoutOptions) => any
  handleRedirectCallback: () => Promise<Auth0Session>
}

const AuthContext = React.createContext<AuthContextValue>({} as any)

export default function AuthProvider({ auth0Client, onLogout, scope, audience, children, appUrl }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, {}, () => {
    // workaround for https://github.com/auth0/auth0-spa-js/issues/684
    // we need to be on auth0-spa-js 1.12.0 until that is fixed
    // @ts-ignore
    const cache = auth0Client.cache.get({
      // @ts-ignore
      client_id: auth0Client.options.client_id,
      audience,
      scope
    })

    const claims = cache?.decodedToken?.claims
    const expired = !!claims?.exp && claims.exp * 1000 < Date.now()
    const session = !expired ? getSessionFromClaims(claims) : null

    return {
      isAuthenticating: !session,
      session
    }
  })

  // logout when another tab logs out
  useEffect(() => {
    function listener(event: StorageEvent) {
      if (event.storageArea != localStorage) return

      if ((event.key || '').startsWith('@@auth0')) {
        // another window/tab logged in/out
        if (!event.newValue || !event.oldValue) {
          window.onbeforeunload = () => null // prevent unsaved changes alert from stopping reload, they cant save anyway
          window.location.reload()
          return
        }

        const oldUser = JSON.parse(event.oldValue)?.body?.decodedToken?.user?.sub
        const newUser = JSON.parse(event.newValue)?.body?.decodedToken?.user?.sub

        if (oldUser && newUser && oldUser !== newUser) {
          window.onbeforeunload = () => null
          window.location.reload()
          return
        }
      }
    }

    window.addEventListener('storage', listener)

    return () => {
      window.removeEventListener('storage', listener)
    }
  }, [])

  const value = useMemo<AuthContextValue>(
    () => ({
      ...state,
      auth0Client,
      authenticate: async ({ ignoreCache } = {}) => {
        dispatch({ type: 'AUTHENTICATING' })

        try {
          await auth0Client.getTokenSilently({ ignoreCache: ignoreCache || !state.session, audience, scope })
          const claims = (await auth0Client.getIdTokenClaims()) as unknown as Auth0Claims
          const session = getSessionFromClaims(claims)
          dispatch({ type: 'AUTHENTICATED', payload: session })

          return session
        } catch (e) {
          if (e instanceof ProgressEvent) {
            return null
          }

          throw e
        }
      },
      logout: (opts) => {
        auth0Client.logout({ federated: true, returnTo: appUrl, ...opts })
        onLogout?.()
        dispatch({ type: 'LOGOUT' })
      },
      handleRedirectCallback: async () => {
        await auth0Client.handleRedirectCallback()
        const claims = (await auth0Client.getIdTokenClaims()) as unknown as Auth0Claims
        const session = getSessionFromClaims(claims)
        dispatch({ type: 'AUTHENTICATED', payload: session })

        return session
      }
    }),
    [auth0Client, state, audience, scope, onLogout, appUrl]
  )

  return (
    <AuthContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </AuthContext.Provider>
  )
}

interface AuthProviderState {
  isAuthenticating: boolean
  session?: Auth0Session
}

function reducer(state: AuthProviderState, action: { type: string; payload?: any }): AuthProviderState {
  const { type, payload } = action

  switch (type) {
    case 'AUTHENTICATING':
      return {
        ...state,
        isAuthenticating: true
      }
    case 'AUTHENTICATED':
      return {
        ...state,
        isAuthenticating: false,
        session: payload
      }
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticating: false
        // keep session so we don't get unexpected UI change before <Authorizer> processes the redirect
      }
    case 'UPDATE_SESSION':
      return {
        ...state,
        session: payload
      }
  }

  return state
}

function getSessionFromClaims(claims: Auth0Claims): Auth0Session {
  if (!claims) {
    return null
  }

  return {
    user_id: claims.sub,
    email: claims.email,
    name: claims.name,
    picture: claims.picture,
    user_metadata: claims[`https://stickybid.com/user_metadata`],
    app_metadata: claims[`https://stickybid.com/app_metadata`],
    issued: claims.iat,
    expires: claims.exp
  }
}

/** Context consumers */
export const useAuth = () => useContext(AuthContext)

export interface WithAuthContext {
  authContext: AuthContextValue
}

export const withAuthContext = <T extends WithAuthContext>(Component: React.ComponentType<T>) =>
  function WithAuthContext(props: Omit<T, keyof WithAuthContext>) {
    return (
      <AuthContext.Consumer>
        {(context: AuthContextValue) => <Component {...(props as T)} authContext={context} />}
      </AuthContext.Consumer>
    )
  }
