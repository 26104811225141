import type { OptionsDocument } from 'paintscout'
import {
  STARTER_ANNUAL_PRICE_ID,
  STARTER_MONTHLY_PRICE_ID,
  PROFESSIONAL_ANNUAL_PRICE_ID,
  PROFESSIONAL_MONTHLY_PRICE_ID
} from '../get-plans'

export function getPresentationAllowance(args: { plan_price_id: string | undefined; options: OptionsDocument }) {
  const { plan_price_id, options } = args

  if (options.options.features.presentation?.customAllowance) {
    return options.options.features.presentation.customAllowance
  } else {
    switch (plan_price_id) {
      case STARTER_ANNUAL_PRICE_ID:
      case STARTER_MONTHLY_PRICE_ID:
        return 1
      case PROFESSIONAL_ANNUAL_PRICE_ID:
      case PROFESSIONAL_MONTHLY_PRICE_ID:
        return 3
      // case TEAM_ANNUAL_PRICE_ID:
      // case TEAM_MONTHLY_PRICE_ID:
      //   return 10
      default:
        return 1
    }
  }
}
